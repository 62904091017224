import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslationDomainService } from '../../domain/translation-domain.service';

@Injectable({ providedIn: 'root' })
export class UserTranslationResolver implements Resolve<string> {
  constructor(private translationDomainService: TranslationDomainService) {}

  resolve() {
    const userLanguage = this.translationDomainService.getUserLanguage();
    if (userLanguage && userLanguage !== this.translationDomainService.getActiveLanguage()) {
      this.translationDomainService.setActiveLanguage(userLanguage);
    }
    return userLanguage;
  }
}
