import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorsInterceptor } from '@app/core/errors/errors-interceptor/errors.interceptor';
import * as Sentry from '@sentry/angular';
import { environment } from '@environment';
import { release } from '@release';

Sentry.init({
  denyUrls: ['localhost'],
  dsn: environment.sentryTravelDns,
  enabled: window.location.hostname !== 'localhost',
  environment: environment.production ? 'production' : 'development',
  ignoreErrors: [' 400 ', ' 401 ', ' 403 '],
  release: release,
  beforeSend: event => (window.location.hostname === 'localhost' ? null : event),
});

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
  ],
})
export class ErrorsModule {}
