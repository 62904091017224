import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environment';

export const RedirectGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const isApplyUrl = state.url.includes('apply');
  if (isApplyUrl) {
    window.location.href = environment.publicWebsite;
  }
  return !isApplyUrl;
};
