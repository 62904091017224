export type BrowserWindow = Window & {
  ReactNativeWebView: unknown;
};

export const enableZoomWebView = () => {
  // Fix for not being able to zoom the view in webView.
  const browserWindow = window as unknown as BrowserWindow;
  if (browserWindow.ReactNativeWebView) {
    const $metaTag = document.querySelector("meta[name='viewport']");
    if ($metaTag) {
      const metaContent = $metaTag.getAttribute('content') + ', user-scalable=0';
      $metaTag.setAttribute('content', metaContent);
    }
  }
};
