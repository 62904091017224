import { AuthStorageService } from '@ess-front/shared';
import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { UserDomainService } from '@app/domain/user-domain.service';
import { of, take } from 'rxjs';

function initializeUser(authStorageService: AuthStorageService, userDomainService: UserDomainService) {
  return () => {
    if (authStorageService.getToken()) {
      userDomainService.getUser$().pipe(take(1)).subscribe();
    }
    return of(true);
  };
}

export const userInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeUser,
  deps: [AuthStorageService, UserDomainService],
  multi: true,
};
