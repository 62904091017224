import { ErrorHandler, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStorageService } from '@ess-front/shared';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: ErrorHandler,
    private router: Router,
    private authStorageService: AuthStorageService,
  ) {}

  intercept<T, K>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<K>> {
    return next.handle(request).pipe(
      catchError((error: unknown) => {
        this.errorHandler.handleError(error);
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized) {
            this.authStorageService.clearAuthData();
            this.router.navigate(['login'], { state: { redirectUrl: this.router.url } });
          } else if (error.status === HttpStatusCode.Forbidden) {
            this.router.navigate(['/forbidden']);
          } else if (error.status === HttpStatusCode.NotFound) {
            this.router.navigate(['/not-found']);
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
