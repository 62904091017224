import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { of } from 'rxjs';
import { HistoryService } from '../services/history/history.service';

function initializeHistory(historyService: HistoryService) {
  return () => {
    historyService.initHistory();
    return of(true);
  };
}

export const historyInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeHistory,
  deps: [HistoryService],
  multi: true,
};
