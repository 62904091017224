import { isDevMode } from '@angular/core';
import { TranslocoHttpLoaderService } from '@app/core/transloco/transloco-htttp-loader.service';
import { TranslocoOptions } from '@jsverse/transloco/lib/transloco.providers';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';

// Only en is registered, I need to register es and pt too so the localized pipes work
registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');

export const translocoConfig: TranslocoOptions = {
  config: {
    availableLangs: [
      {
        id: 'en',
        label: 'English',
      },
      {
        id: 'es',
        label: 'Spanish',
      },
      {
        id: 'pt',
        label: 'Portuguese',
      },
    ],
    defaultLang: 'en',
    reRenderOnLangChange: true,
    prodMode: !isDevMode(),
  },
  loader: TranslocoHttpLoaderService,
};
