import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslationDomainService } from '@domain/translation-domain.service';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TripTranslationResolver implements Resolve<string> {
  constructor(private translationDomainService: TranslationDomainService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const tripHash = route.params.hash;
    return this.translationDomainService.getTripLanguage$(tripHash).pipe(
      tap(language => {
        if (language && language !== this.translationDomainService.getActiveLanguage()) {
          this.translationDomainService.setActiveLanguage(language);
        }
      }),
    );
  }
}
