import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { TalkJsDomainService } from '@domain/talk-js-domain.service';
import { of } from 'rxjs';

function initializeTalkJs(talkJsDomainService: TalkJsDomainService) {
  return () => {
    talkJsDomainService.checkTalkJsInitialization$().subscribe();
    return of(true);
  };
}

export const talkJsInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeTalkJs,
  deps: [TalkJsDomainService],
  multi: true,
};
