import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserDomainService } from '@app/domain/user-domain.service';
import { AuthStorageService, BrandType, UserRole } from '@ess-front/shared';
import { User } from '@shared/models/user/user.model';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingSurveyGuard implements CanActivate {
  private readonly HAVE_TO_DO_ONBOARDING_BRANDS = [BrandType.ESSENTIALIST, BrandType.LATAM];
  private readonly MEMBERS_AREA_LOGIN_URL = '/login';
  private readonly MEMBERS_AREA_URL = '/members-area';
  private readonly ONBOARDING_SURVEY_PATH = 'onboarding-survey';
  private readonly ONBOARDING_SURVEY_URL = '/onboarding-survey';

  constructor(
    private authStorageService: AuthStorageService,
    private userDomainService: UserDomainService,
    private router: Router,
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const isOnboardingRoute = activatedRouteSnapshot.url[0].path === this.ONBOARDING_SURVEY_PATH;

    if (this.authStorageService.getToken()) {
      return this.userDomainService.getCurrentUser$().pipe(
        filter(user => !!user),
        switchMap(user => {
          if (this.shouldRedirectToMembersArea(isOnboardingRoute, user)) {
            this.router.navigate([this.MEMBERS_AREA_URL]);
            return of(false);
          }
          if (this.shouldRedirectToOnboarding(isOnboardingRoute, user)) {
            this.router.navigate([this.ONBOARDING_SURVEY_URL]);
            return of(false);
          }
          return of(true);
        }),
        take(1),
        catchError((err: unknown) => this.onError$(err)),
      );
    } else {
      this.router.navigate([this.MEMBERS_AREA_LOGIN_URL]);
      return of(false);
    }
  }

  private shouldRedirectToMembersArea(isOnboardingRoute: boolean, user: User): boolean {
    return isOnboardingRoute && (user.isOnboardingDone || !this.hasToDoOnboarding(user.brand));
  }

  private shouldRedirectToOnboarding(isOnboardingRoute: boolean, user: User): boolean {
    return !isOnboardingRoute && !user.isOnboardingDone && this.hasToDoOnboarding(user.brand);
  }

  private hasToDoOnboarding(userBrand: BrandType): boolean {
    const userRole = this.authStorageService.getUserRole();
    if (userRole != UserRole.MEMBER) return false;
    return this.HAVE_TO_DO_ONBOARDING_BRANDS.includes(userBrand);
  }

  private onError$(error: unknown): Observable<boolean> {
    this.router.navigate([this.MEMBERS_AREA_LOGIN_URL]);
    throw error;
  }
}
