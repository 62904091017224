<ess-loading />
<router-outlet (activate)="loading = false">
  @if (loading) {
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  }
</router-outlet>
<ess-loading-screen />
