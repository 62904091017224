import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class LoadingService {
  private _loading$: BehaviorSubject<number> = new BehaviorSubject(0);
  readonly loading$: Observable<number> = this._loading$
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  set loading(activeCalls) {
    this._loading$.next(activeCalls);
  }
}
